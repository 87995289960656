<template>
  <div>
    <el-divider content-position="left" class="tittle">Shipment Log</el-divider>
    <!-- 表单 -->
    <el-form
      :inline="true"
      :model="searchForm"
      class="demo-form-inline"
      ref="ruleForm"
    >
      <el-form-item prop="orderNo">
        <el-input
          v-model="searchForm.orderNo"
          placeholder="Tracking id"
          @keyup.native.enter="onSubmit"
        ></el-input>
      </el-form-item>
      <el-form-item prop="shelfName">
        <el-input
          v-model="searchForm.shelfName"
          placeholder="Shelf Name"
          @keyup.native.enter="onSubmit"
        ></el-input>
      </el-form-item>
      <el-form-item prop="date">
        <el-date-picker
          v-model="searchForm.date"
          type="daterange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item class="searchBtn">
        <el-button type="primary" @click="onSubmit">Search</el-button>
        <el-button type="default" @click="resetForm('ruleForm')"
          >Reset</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <el-table :data="tableData" style="width: 100%" stripe>
      <el-table-column prop="created_at" label="Date"> </el-table-column>
      <el-table-column
        prop="employee.firstname,employee.lastname"
        label="Operator"
      >
        <template slot-scope="scope">
          {{ scope.row.employee.firstname }}{{ scope.row.employee.lastname }}
        </template>
      </el-table-column>
      <el-table-column prop="type_desc" label="Type"> </el-table-column>
      <el-table-column prop="tracking_id" label="Tracking Id">
      </el-table-column>
      <el-table-column prop="desc" label="Description"> </el-table-column>
    </el-table>
    <!-- 操作按钮 -->
    <div class="btn">
      <el-button type="primary" @click="reduce" :disabled="currentid == 0"
        >previous page
      </el-button>
      <el-button type="primary" @click="add" :disabled="length < 20"
        >next page</el-button
      >
    </div>
  </div>
</template>

<script>
import { logs } from '@/api/user.js'
export default {
  data() {
    return {
      searchForm: {
        orderNo: '',
        shelfName: '',
        date: ''
      },
      tableData: [],
      maxid: 0,
      ltid: 0,
      gtid: '',
      currentid: 0,
      length: '',
      isfirst: true,
      currentdata: []
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    // 重置
    resetForm() {
      this.searchForm.data = ''
      this.$refs.ruleForm.resetFields()
      this.ltid = 0
      this.gtid = ''
      this.currentid = 0
      this.isfirst = true
      this.getlist()
    },
    // 渲染
    async getlist() {
      const data = await logs({
        ltid: this.ltid,
        gtid: this.gtid,
        tracking_id: this.searchForm.orderNo,
        shelf_name: this.searchForm.shelfName,
        start_date: this.searchForm.date[0],
        end_date: this.searchForm.date[1]
      })
      // console.log(data)
      this.tableData = data
      this.length = data.length

      if (data.length > 0) {
        this.ltid = data[data.length - 1].id
        if (this.isfirst) {
          this.maxid = data[0].id
          this.isfirst = false
        }
        // 上一页禁用
        if (data[0].id === this.maxid) {
          this.currentid = 0
        }
      }
    },
    // 查询提交
    onSubmit() {
      this.ltid = 0
      this.gtid = ''
      this.isfirst = true
      this.getlist()
    },
    // 上一页
    reduce() {
      if (this.tableData.length > 0) {
        this.ltid = ''
        this.gtid = this.tableData[0].id
      } else {
        this.gtid = ''
        this.ltid = this.currentdata[0].id + 1
      }
      this.getlist()
    },
    // 下一页
    add() {
      this.currentdata = this.tableData
      this.gtid = ''
      this.currentid = this.ltid
      this.getlist()
    }
  }
}
</script>

<style lang="less" scoped>
.el-divider__text.is-left {
  font-size: 30px;
  font-weight: bold;
  color: #333;
}
.tittle {
  margin-bottom: 60px;
}
/deep/.el-table tr th {
  background-color: #f5f7fa;
}
.btn {
  margin-top: 40px;
  text-align: right;
}
.searchBtn {
  margin-left: 20px;
}
</style>
